








































import { Component, Prop, Vue } from 'vue-property-decorator'

import RequirementTag from '@/components/RequirementTag.vue'

@Component({
  components: {
    RequirementTag,
  },
})
export default class ElementRow extends Vue {

  @Prop({ required: false })
  required?: boolean

  @Prop({ default: false, type: Boolean })
  compact!: boolean

  @Prop({ default: true })
  highlight!: boolean
}

import Vue from 'vue'

import '@/common'
import App from '@/App.vue'
import { makeRouter } from '@/router'
import i18n from '@/i18n'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: makeRouter(),
  i18n,
}).$mount('#app')
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import * as yup from 'yup'

import Dialog from '@/components/Dialog.vue'
import DialogField from '@/components/DialogField.vue'

const LINK_SCHEMA = yup
  .string()
  .matches(/.+:\/\/.*/, {
    message: 'リンクのURLはプロトコル（https://など）を含む全文を入力してください。',
  })

@Component({
  components: {
    Dialog,
    DialogField,
  },
})
export default class CreateLinkDialog extends Vue {

  @Prop({ required: false })
  initialText?: string

  @Prop({ required: false })
  initialUrl?: string

  @Prop({ default: () => _.noop })
  onSubmit!: (text: string, url?: string) => Promise<void>

  @Prop({ default: () => _.noop })
  onCancel!: () => Promise<void>

  text = ''

  url = ''

  error: yup.ValidationError | null = null

  @Watch('initialText', { immediate: true })
  @Watch('initialUrl', { immediate: true })
  initializeOnParametersChanged() {
    if (this.initialText !== undefined) {
      this.text = this.initialText
    }

    if (this.initialUrl !== undefined) {
      this.url = this.initialUrl
    }
  }

  get canCreate() {
    return this.text && this.url
  }

  get canRemove() {
    return this.initialText && this.initialUrl
  }

  async doCreate() {
    if (this.canCreate) {
      try {
        await LINK_SCHEMA.validate(this.url)
        this.error = null

      } catch (error) {
        this.error = error
      }

      if (this.error) {
        return
      }

      await this.onSubmit(this.text, this.url)
    }
  }

  async doRemove() {
    if (this.canRemove) {
      await this.onSubmit(this.text, '')
    }
  }
}

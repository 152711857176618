import { plainToClass } from 'class-transformer'
import { Form } from '@/models/form'
import { Result } from '@/models/result'
import * as models from './models'
import { Client } from '@/api/entry/client'

const CLIENT = new Client
export class EntryApi {
  constructor(
    private readonly formId: string,
    private readonly prizeId: string,
    private readonly serial?: string
  ) {

  }

  async getForm() {
    const response = await CLIENT.get<{params: models.GetFormRequest}, models.GetFormResponse>(
      `/form/${ this.formId }`,
      {
        params: {
          serialCode: this.serial,
          prizeId: this.prizeId,
        },
      }
    )

    if (response.status === 200) {
      const { formJson, entryStartAt, deadlineAt, serialUsedFlag } = response.data

      return {
        entryStartAt,
        deadlineAt,
        serialUsedFlag,
        form: plainToClass(Form, formJson),
        result: new Result({
          sample: false,
          serial: this.serial,
        }),
      }
    }
  }

  async checkTestCode(testCode: string) {
    const response = await CLIENT.get<{ params: models.checkTestCodeRequest}, models.CheckTestCodeResponse>(
      `/form/${ this.formId }/check`,
      {
        params: {
          testCode,
          serialCode: this.serial,
        },
      }
    )

    if (response.status === 200) {
      const { formJson, validFlag, serialUsedFlag } = response.data

      return {
        validFlag,
        serialUsedFlag,
        form: plainToClass(Form, formJson),
      }
    }
  }

  async postWinner(result: Result, testCode?: string) {
    const data: models.PostEntriesRequest = {
      formId: this.formId,
      prizeId: this.prizeId,
      entryJson: result.toEntry(),
      testCode,
      serialCode: this.serial,
    }

    await CLIENT.post<models.PostEntriesRequest>('/winner', data)
  }
}

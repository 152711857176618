import Vue from 'vue'
import VueRouter from 'vue-router'

import { Routes } from '@/router/routes'

import EditorApp from '@/apps/EditorApp.vue'
import EntryApp from '@/apps/EntryApp.vue'

import EntryPage from '@/pages/EntryPage.vue'
import ConfirmPage from '@/pages/ConfirmPage.vue'
import FinishPage from '@/pages/FinishPage.vue'
import CompletePage from '@/pages/CompletePage.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import TestPage from '@/pages/TestPage.vue'
import DuplicatePage from '@/pages/DuplicatePage.vue'

Vue.use(VueRouter)

export function makeRouter() {
  return router
}

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      components: {
        default: EntryApp,
        editor: EditorApp,
      },
      redirect: {
        name: Routes.entry,
      },
      children: [
        {
          name: Routes.entry,
          path: '/1',
          component: EntryPage,
        },
        {
          name: Routes.confirm,
          path: '/2',
          component: ConfirmPage,
        },
        {
          name: Routes.finish,
          path: '/3',
          component: FinishPage,
        },
        {
          name: Routes.complete,
          path: '/complete',
          component: CompletePage,
        },
        {
          name: Routes.test,
          path: '/test',
          component: TestPage,
        },
        {
          name: Routes.duplicate,
          path: '/duplicate',
          component: DuplicatePage,
        },
      ],
    },
    {
      name: Routes.error,
      path: '/error',
      component: ErrorPage,
    },
    {
      path: '/*',
      redirect: {
        name: Routes.error,
      },
    },
  ],
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ToolbarFrame',{attrs:{"position":"top-right"},scopedSlots:_vm._u([{key:"target",fn:function(){return [_vm._t("default",[_c('TextWidgetRenderer',{attrs:{"widget":_vm.widget}},[_c('TextWidgetContentEditor')],1)])]},proxy:true},{key:"toolbar",fn:function(){return [_c('WidgetToolbar',{attrs:{"widget":_vm.widget}},[(_vm.features.includes('alignment'))?[_c('AlignTool',{attrs:{"alignment":"start","icon":"editor_text_align_left"},model:{value:(_vm.widget.alignment),callback:function ($$v) {_vm.$set(_vm.widget, "alignment", $$v)},expression:"widget.alignment"}}),_c('AlignTool',{attrs:{"alignment":"center","icon":"editor_text_align_center"},model:{value:(_vm.widget.alignment),callback:function ($$v) {_vm.$set(_vm.widget, "alignment", $$v)},expression:"widget.alignment"}}),_c('AlignTool',{attrs:{"alignment":"end","icon":"editor_text_align_right"},model:{value:(_vm.widget.alignment),callback:function ($$v) {_vm.$set(_vm.widget, "alignment", $$v)},expression:"widget.alignment"}})]:_vm._e(),(_vm.features.includes('color'))?[_c('ColorTool',{attrs:{"icon":"editor_font_color"},model:{value:(_vm.widget.color),callback:function ($$v) {_vm.$set(_vm.widget, "color", $$v)},expression:"widget.color"}})]:_vm._e(),(_vm.features.includes('link'))?[_c('Tool',{attrs:{"disabled":!_vm.canLink,"on-activate":_vm.triggerLink}},[_c('uv-icon',{class:[
              _vm.canLink
                ? 'tw-text-gray-dark'
                : 'tw-text-gray-light'
            ],attrs:{"icon":"link"}})],1)]:_vm._e(),(_vm.features.includes('bold'))?[_c('Tool',{attrs:{"disabled":!_vm.canLink,"on-activate":_vm.triggerBold}},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-font-bold tw-w-3 tw-h-4 tw-text-lg tw-select-none",class:[
              _vm.isBold
                ? 'tw-text-black'
                : 'tw-text-gray-light'
            ],style:({
              marginBottom: '1px',
            })},[_vm._v(" B ")])])]:_vm._e()],2)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
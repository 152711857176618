

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import Palette from '@/components/Palette.vue'
import ElementPaletteItem from '@/components/ElementPaletteItem.vue'

import {
  BirthdayElement,
  CustomElement,
  Element,
  ElementType,
  EmailElement,
  GenderElement,
  NameElement,
  KanaNameElement,
  JapaneseAddressElement,
  PhoneElement,
  OptionalTwitterScreenNameElement,
} from '@/models/element'

@Component({
  components: {
    Palette,
    ElementPaletteItem,
  },
})
export default class ElementPalette extends Vue {

  @Prop({ required: true })
  elements!: Element[]

  @Prop({ default: () => _.noop })
  onElement!: (element: Element) => Promise<void>

  get canCreate() {
    return this.elements.length < 30
  }

  get canCreateName() {
    return !this.elements.some(element => element.type === 'name')
  }

  get canCreateNameKana() {
    return !this.elements.some(element => element.type === 'name-kana')
  }

  get canCreateAddressJapan() {
    return !this.elements.some(element => element.type === 'address-japan')
  }

  get canCreatePhone() {
    return !this.elements.some(element => element.type === 'phone')
  }

  get canCreateGender() {
    return !this.elements.some(element => element.type === 'gender')
  }

  get canCreateEmail() {
    return !this.elements.some(element => element.type === 'email')
  }

  get canCreateBirthday() {
    return !this.elements.some(element => element.type === 'birthday')
  }

  get canCreateScreenName() {
    return !this.elements.some(element => ['twitter-screen-name', 'optional-twitter-screen-name'].includes(element.type))
  }

  async onSelect(type: ElementType) {
    let element: Element | undefined

    switch (type) {
      case 'name':
        if (this.canCreateName) {
          element = new NameElement()
        }
        break

      case 'name-kana':
        if (this.canCreateNameKana) {
          element = new KanaNameElement()
        }
        break

      case 'address-japan':
        if (this.canCreateAddressJapan) {
          element = new JapaneseAddressElement()
        }
        break

      case 'phone':
        if (this.canCreatePhone) {
          element = new PhoneElement()
        }
        break

      case 'gender':
        if (this.canCreateGender) {
          element = new GenderElement()
        }
        break

      case 'email':
        if (this.canCreateEmail) {
          element = new EmailElement()
        }
        break

      case 'birthday':
        if (this.canCreateBirthday) {
          element = new BirthdayElement()
        }
        break

      case 'optional-twitter-screen-name':
        if (this.canCreateScreenName) {
          element = new OptionalTwitterScreenNameElement()
        }

        break

      case 'custom':
        element = new CustomElement()
        break

      default:
        // この要素は自分で作れない。
    }

    if (element) {
      await this.onElement(element)
    }
  }
}

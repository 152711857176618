
























































import { Component, Prop, Vue } from 'vue-property-decorator'

import ElementView from '@/components/ElementView.vue'
import ElementRow from '@/components/ElementRow.vue'
import ElementInput from '@/components/ElementInput.vue'

import { TwitterScreenNameElement, OptionalTwitterScreenNameElement } from '@/models/element'
import { TwitterScreenNameData } from '@/models/result'

@Component({
  components: {
    ElementView,
    ElementRow,
    ElementInput,
  },
})
export default class TwitterScreenNameElementView extends Vue {

  @Prop({ required: true })
  element!: TwitterScreenNameElement | OptionalTwitterScreenNameElement

  @Prop({ required: true })
  data!: TwitterScreenNameData

  @Prop({ required: true })
  edit!: boolean

  get value() {
    return this.data.value
  }
}

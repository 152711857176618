var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-px-3",class:[
    _vm.$mobile('tw-space-y-8 tw-shadow tw-mx-2 tw-pt-3'),
    _vm.$desktop('tw-space-y-3 tw-shadow tw-pt-4 tw-mx-2') ],style:(_vm.edit ? '' : _vm.$desktop({
    background: 'linear-gradient(to right, #F6F6F6 0%, #F6F6F6 34%, #FFF 34%,  #FFF 100%)'
    }))},[_vm._l((_vm.form.elements),function(element){return _c('div',{key:element.id,staticClass:"tw-flex tw-space-x-3",class:[
      _vm.$desktop('tw-items-start') ]},[(!_vm.widget.preview)?_vm._t("controls",null,{"element":element}):_vm._e(),_c(_vm.componentFor(element),{tag:"component",attrs:{"element":element,"data":_vm.dataFor(element),"edit":_vm.edit}})],2)}),(_vm.canEditElements)?[_c('uv-dialog',{attrs:{"dialog":_vm.dialog,"persistent":false,"dialog-class":'element-palette-dialog'},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('uv-button',_vm._g({staticClass:"tw-w-full",attrs:{"variant":"green"}},on),[_vm._v(" 入力項目を追加する ")])]}}],null,false,138422051)},[_c('ElementPalette',{attrs:{"elements":_vm.form.elements,"on-element":_vm.insert}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
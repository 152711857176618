
















import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import Tool from '@/components/Tool.vue'

@Component({
  components: {
    Tool,
  },
})
export default class TrashTool extends Vue {

  @Prop({ default: () => _.noop })
  onTrash!: () => Promise<void>

  @Prop({ required: false })
  disabled!: boolean
}

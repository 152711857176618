







import { Component, Prop, Vue } from 'vue-property-decorator'

import { HeaderWidget } from '@/models/widget'

import TextWidgetRenderer from '@/components/TextWidgetRenderer.vue'

@Component({
  components: {
    TextWidgetRenderer,
  },
})
export default class HeaderWidgetView extends Vue {

  @Prop({ required: true })
  widget!: HeaderWidget
}

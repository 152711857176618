
































import { Component, Prop, Vue } from 'vue-property-decorator'
import noop from 'lodash/noop'

@Component({
  components: {},
})
export default class TestPage extends Vue {

  @Prop({ default: () => noop })
  onCheck!: (testCode: string) => Promise<boolean>

  value: string = ''

  waiting: boolean = false

  error: boolean = false

  async check() {
    try {
      this.waiting = true
      this.error = false

      // テストコードが正しいかを確認する
      this.error = !(await this.onCheck(this.value))

      // ページが遷移しなかった場合，ボタンを押せるようにする
      this.waiting = false

    } catch (error) {
      await this.$router.push({
        name: this.$routes.error,
      })
    }
  }
}

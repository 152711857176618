





















































import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator'
import { SplashWidget } from '@/models/widget'
import { EditorApi } from '@/api/editor'

@Component
export default class SplashWidgetView extends Vue {

  @Inject({ default: undefined })
  api?: EditorApi

  @Ref('input')
  input!: HTMLInputElement

  @Prop({ required: true })
  widget!: SplashWidget

  @Prop({ required: true })
  edit!: boolean

  error: string | null = null

  active = false

  triggerSelect() {
    this.input.click()
  }

  get accept() {
    return '.png,.jpg,.jpeg,.gif'
  }

  private async handleDrop(event: DragEvent) {
    if (!event.dataTransfer) {
      return
    }

    if (event.dataTransfer.files.length === 0) {
      return
    }

    await this.upload(event.dataTransfer.files[0])
  }

  private async handleSelect(event: InputEvent) {
    const { target } = event

    if (!(target instanceof HTMLInputElement)) {
      return
    }

    const { files } = target

    if (!files) {
      return
    }

    if (files.length === 0) {
      return
    }

    await this.upload(files[0])
  }

  private async upload(file: File) {
    if (file.size > 10 * 1024 * 1024) {
      this.error = '画像のサイズは10MB以下にしてください'
      return

    } else {
      this.error = null
    }

    if (this.api) {
      this.active = true

      try {
        const url = await this.api.sendUpload(file)

        if (url) {
          this.widget.url = url
        }

      } finally {
        this.active = false
      }
    }
  }
}

























import { Component, Prop, Vue } from 'vue-property-decorator'

import { Form } from '@/models/form'
import { Result } from '@/models/result'

import LayoutPageTemplate from '@/components/LayoutPageTemplate.vue'
import DynamicFormButton from '@/components/DynamicFormButton.vue'

@Component({
  components: {
    LayoutPageTemplate,
    DynamicFormButton,
  },
})
export default class EntryPage extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  result!: Result

  @Prop({ default: false })
  edit!: boolean

  async submit() {
    if (await this.result.validate()) {
      await this.$router.push({
        name: this.$routes.confirm,
      })
    }
  }
}











import { Component, Prop, Vue } from 'vue-property-decorator'

import { Form } from '@/models/form'
import { Result } from '@/models/result'

import LayoutPageTemplate from '@/components/LayoutPageTemplate.vue'

@Component({
  components: {
    LayoutPageTemplate,
  },
})
export default class CompletePage extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  result!: Result

  @Prop({ default: false })
  edit!: boolean
}


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Palette extends Vue {

  @Prop({ required: true })
  columns!: number

  @Prop({ default: false })
  disabled!: boolean
}

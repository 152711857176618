






































import { Component, Prop, Vue } from 'vue-property-decorator'

import { ToolbarPosition } from '@/components/ToolbarFrame'

@Component
export default class ToolbarFrame extends Vue {

  @Prop({ required: true })
  position!: ToolbarPosition

  focus = false
}

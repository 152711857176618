


























import { Component, Inject, Ref, Vue, Watch } from 'vue-property-decorator'
import DOMPurify from 'dompurify'
import _ from 'lodash'

import CreateLinkDialog from '@/components/CreateLinkDialog.vue'
import TextWidgetEditor from './TextWidgetEditor.vue'

@Component({
  components: {
    CreateLinkDialog,
  },
})
export default class TextWidgetContentEditor extends Vue {

  @Ref('editable')
  editable!: HTMLElement

  @Inject()
  editor!: TextWidgetEditor

  created() {
    this.editor.listen(this)
    document.addEventListener('selectionchange', this.onSelectionChange)
  }

  destroyed() {
    this.editor.listen(null)
    document.removeEventListener('selectionchange', this.onSelectionChange)
  }

  get widget() {
    return this.editor.widget
  }

  get features() {
    return this.editor.features
  }

  onShortcut(event: KeyboardEvent) {
    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case 'i':
        case 'u':
          event.preventDefault()
          break

        case 'b':
          event.preventDefault()
          this.onBold()
          break

        case 'k':
          event.preventDefault()
          this.onLink()
          break

        default:
      }
    }
  }

  onUpdate() {
    this.widget.text = this.editable.innerHTML
    this.syncBoldState()
  }

  //
  // Copy/Paste
  //

  onPaste(event: ClipboardEvent) {
    this.onDataTransfer(event.clipboardData)
  }

  onDrop(event: DragEvent) {
    this.onDataTransfer(event.dataTransfer)
  }

  private onDataTransfer(data: DataTransfer | null) {
    if (!data) {
      return
    }

    const content =
      data.getData('text/html') ||
      data.getData('text/plain')

    if (content) {
      window.document.execCommand('insertHTML', false, this.sanitizeText(content))
    }
  }

  //
  // Range
  //

  range: Range | null = null

  onSelectionChange() {
    const selection = document.getSelection()
    this.range = null

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)

      if (range.intersectsNode(this.$el)) {
        this.range = range
      }
    }
  }

  //
  // Bold
  //

  bold = false

  onBold() {
    if (!this.features.includes('bold')) {
      return
    }

    if (!this.range) {
      return
    }

    window.document.execCommand('bold', false, undefined)
    this.syncBoldState()
  }

  @Watch('range', { immediate: true })
  syncBoldState() {
    if (this.range) {
      this.bold = document.queryCommandState('bold')

    } else {
      this.bold = false
    }
  }

  //
  // Link
  //

  linkDialog = false

  linkRange = document.createRange()

  get currentLinkText() {
    return this.linkRange.toString()
  }

  get currentLinkElement() {
    let node: Node | null = this.linkRange.startContainer

    while (node) {

      // ベースケース：親editor要素まで行ったら、<a>要素が見つかるわけがない。
      if (node === this.editor.$el) {
        return null
      }

      // 仕様：テキストが完全選択されていない場合、「新規」リンクとして扱う。
      if (node instanceof HTMLAnchorElement &&
          node.textContent === this.currentLinkText) {

        break
      }

      node = node.parentNode
    }

    return node
  }

  get currentLinkUrl() {
    const anchor = this.currentLinkElement

    if (anchor) {
      const attribute = anchor.attributes.getNamedItem('href')

      if (attribute) {
        return attribute.value
      }
    }

    return null
  }

  onLink() {

    // リンク化が無効に設定している。
    if (!this.features.includes('link')) {
      return
    }

    // 何も選択されていないから実行できない。
    if (!this.range) {
      return
    }

    this.linkRange = this.range
    this.linkDialog = true
  }

  onInsertLink(text: string, url: string) {
    this.linkDialog = false
    let html: string

    if (url) {
      const anchor = document.createElement('a')
      anchor.title = url
      anchor.href = url
      anchor.target = '_blank'
      anchor.rel = 'noreferrer'

      // ここはバグがる。例えば link => bold => link すると、boldがなくなる。
      // 一番深い要素のHTMLを設定すればなおるが、エッジケースが激しいから諦める。
      // 特に、`insertHTML`後カーサーが設定されなくて困る。将来のエンジニアに任せる。
      anchor.innerHTML = this.sanitizeLinkText(text)
      html = anchor.outerHTML

    } else {
      const span = document.createElement('span')
      span.innerHTML = this.sanitizeLinkText(text)
      html = span.outerHTML
    }

    this.selectLinkRange()
    document.execCommand('insertHTML', false, html)
  }

  onCancelLink() {
    this.linkDialog = false
    this.selectLinkRange()
  }

  private selectLinkRange() {
    this.editable.focus()
    const selection = window.getSelection() as Selection
    selection.removeAllRanges()
    selection.addRange(this.linkRange)
  }

  //
  // Sanitization
  //

  private sanitizeLinkText(html: string) {
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: _.compact([
        'div',
        'span',
        'br',
        this.features.includes('bold') && 'b',
      ]),
    })
  }

  private sanitizeText(html: string) {
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: _.compact([
        'a',
        'div',
        'span',
        'br',
        this.features.includes('bold') && 'b',
      ]),
      ALLOWED_ATTR: [
        'href',
        'rel',
        'target',
        'title',
      ],
    })
  }
}

import Vue from 'vue'
import BelugaUi from '@uniquevision/libraries.beluga_ui'

Vue.use(
  BelugaUi({
    icons: {
      resolver: key => require(`!!html-loader!../assets/${key}.svg`),
    },
  })
)













import { Component, Prop, Vue } from 'vue-property-decorator'

import WidgetToolbar from '@/components/WidgetToolbar.vue'
import ToolbarFrame from '@/components/ToolbarFrame.vue'
import DelimiterWidgetView from '@/components/widgets/DelimiterWidgetView.vue'
import Tool from '@/components/Tool.vue'
import { DelimiterWidget } from '@/models/widget'

@Component({
  components: {
    ToolbarFrame,
    DelimiterWidgetView,
    WidgetToolbar,
    Tool,
  },
})
export default class DelimiterWidgetEditor extends Vue {

  @Prop({ required: true })
  widget!: DelimiterWidget
}

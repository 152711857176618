

















import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class Tool extends Vue {

  /**
   * 無効状態かどうか
   */
  @Prop({ default: false })
  disabled!: boolean

  /**
   * このツールを起動する時
   */
  @Prop({ default: () => _.noop })
  onActivate!: () => void
}











import { Component, Prop, Vue } from 'vue-property-decorator'

import PaletteItem from '@/components/PaletteItem.vue'

@Component({
  components: {
    PaletteItem,
  },
})
export default class ElementPaletteItem extends Vue {

  @Prop({ required: true })
  label!: string

  @Prop({ default: false })
  disabled!: boolean
}

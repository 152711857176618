




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Tool from '@/components/Tool.vue'
import Dialog from '@/components/Dialog.vue'
import DialogField from '@/components/DialogField.vue'

@Component({
  components: {
    Tool,
    Dialog,
    DialogField,
  },
})
export default class ColorTool extends Vue {

  @Prop({ required: true })
  value!: string

  @Prop({ required: true })
  icon!: string

  dialog = false

  selectedColor = ''

  get colors() {
    return [
      'black',
      'gray',
      'purple',
      'blue',
      'skyblue',
      'green',
      'red',
      'orange',
      'pink',
    ]
  }

  openTool() {
    this.dialog = true
  }

  closeTool() {
    this.dialog = false
  }

  save() {
    this.dialog = false
    this.$emit('input', this.selectedColor)
  }

  cancel() {
    this.dialog = false
    this.selectedColor = this.value
  }

  @Watch('value', { immediate: true })
  syncSelectedColorOnValueChanged() {
    this.selectedColor = this.value
  }
}

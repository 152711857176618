




















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ErrorPage extends Vue {
  browserBack() {
    window.history.back()
  }
}

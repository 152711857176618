













import { Component, Prop, Vue } from 'vue-property-decorator'
import Tool from '@/components/Tool.vue'
import { Alignment } from '@/models/alignment'

@Component({
  components: {
    Tool,
  },
})
export default class AlignTool extends Vue {

  @Prop({ required: true })
  alignment!: Alignment

  @Prop({ required: true })
  icon!: string

  @Prop({ required: true })
  value!: Alignment

  get isActive() {
    return this.alignment === this.value
  }
}

import Vue from 'vue'
import _ from 'lodash'
import * as yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import {
  Element,
  EmailElement,
  GenderElement,
  JapaneseAddressElement,
  PhoneElement,
  TwitterScreenNameElement,
  OptionalTwitterScreenNameElement,
  CustomElement,
  NameElement,
  KanaNameElement,
  BirthdayElement,
  SerialElement,
} from '@/models/element'
import { EntryData } from '@/api/entry/models'
import { Gender } from './gender'
import { getYear, startOfDay } from 'date-fns'

export class ResultData<
  ELEMENT extends Element,
  SCHEMA extends yup.AnySchema,
  VALUE = yup.InferType<SCHEMA>
> {
  constructor(
    readonly element: ELEMENT,
    private readonly schema: SCHEMA,
    private readonly writeValue: (value: Partial<VALUE>, data: EntryData) => void,
    private readonly sampleValue: Partial<VALUE>
  ) {
    this.actualValue = this.schema.getDefault()
  }

  readonly id = uuidv4()

  sample = false

  private readonly actualValue: Partial<VALUE>

  private error: yup.ValidationError | null = null

  get value(): Partial<VALUE> {
    return this.sample
      ? this.sampleValue
      : this.actualValue
  }

  async validate() {
    try {
      await this.schema.validate(this.value, {
        abortEarly: false,
      })

      this.error = null
      return true

    } catch (error) {
      if (error instanceof yup.ValidationError) {
        this.error = error
      }
      return false
    }
  }

  errorFor(name: keyof VALUE) {
    if (this.error) {
      for (const innerError of this.error.inner) {
        if (innerError.path === name) {
          return innerError.message
        }
      }
    }

    return null
  }

  write(data: EntryData) {
    this.writeValue(this.value, data)
  }
}

function makeTwitterScreenNameData(element: TwitterScreenNameElement | OptionalTwitterScreenNameElement) {
  return new ResultData(
    element,
    yup.object({
      screenName: yup
        .string()
        .min(4)
      // 緊急対応のため一時的にバリデーションを解除した
      // .max(15)
      // .matches(/^\w*$/, {
      //   message: '半角英数字15文字以内で入力してください。',
      // })
        .matches(/^[a-zA-Z0-9_.]*$/, {
          message: '半角英数字で入力してください。',
        })
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .min(4)
            // 緊急対応のため一時的にバリデーションを解除した
            // .max(15)
            // .matches(/^\w*$/, {
            //   message: '半角英数字15文字以内で入力してください。',
            // })
            .matches(/^[a-zA-Z0-9_.]*$/, {
              message: '半角英数字で入力してください。',
            })
            .required(),
        })
      ,
    }),
    (value, data) => {
      if (element instanceof OptionalTwitterScreenNameElement) {
        data.optionalScreenName = value.screenName
        return
      }
      data.screenName = value.screenName
    },
    {
      screenName: 'beluga',
    }
  )
}

export type TwitterScreenNameData = ReturnType<typeof makeTwitterScreenNameData>

function makeGenderData(element: GenderElement) {
  return new ResultData(
    element,
    yup.object({
      gender: yup.lazy(() =>  yup
        .string()
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        })
      ),
    }),
    (value, data) => {
      data.gender = value.gender as Gender
    },
    {
      gender: '男',
    }
  )
}

export type GenderData = ReturnType<typeof makeGenderData>

function makeNameData(element: NameElement) {
  return new ResultData(
    element,
    yup.object({
      first: yup
        .string()
        .max(20)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),

      last: yup
        .string()
        .max(20).when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),
    }),
    (value, data) => {
      data.name = {
        first: value.first || '',
        last: value.last || '',
      }
    },
    {
      first: '太郎',
      last: '田中',
    }
  )
}

export type NameData = ReturnType<typeof makeNameData>

function makeKanaNameData(element: KanaNameElement) {
  return new ResultData(
    element,
    yup.object({
      first: yup
        .string()
        .max(20)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),

      last: yup
        .string()
        .max(20)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),
    }),
    (value, data) => {
      data.nameKana = {
        first: value.first || '',
        last: value.last || '',
      }
    },
    {
      first: 'タロウ',
      last: 'タナカ',
    }
  )
}

export type KanaNameData = ReturnType<typeof makeKanaNameData>

function makeEmailData(element: EmailElement) {
  return new ResultData(
    element,
    yup.object({
      email: yup.lazy(() =>  yup
        .string()
        .default('')
        .email('正しいメールアドレスを入力してください。')
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        })
      ),

      emailConfirmation: yup
        .string()
        .default('')
        .equals([yup.ref('email')], '上記のメールアドレスと異なっています。')
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),
    }),
    (value, data) => {
      data.email = value.email
    },
    {
      email: 'tanaka_tarou@example.com',
      emailConfirmation: 'tanaka_tarou@example.com',
    }
  )
}

export type EmailData = ReturnType<typeof makeEmailData>

function makePhoneData(element: PhoneElement) {
  return new ResultData(
    element,
    yup.object({
      phone: yup
        .string()
        .max(20)
        .matches(/^[0-9-]*$/, {
          message: '半角数字のみ（「-」ハイフン可）で入力してください。',
        })
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required()
            .min(1),
        }),
    }),
    (value, data) => {
      data.phone = value.phone
    },
    {
      phone: '090-1234-5678',
    }
  )
}

export type PhoneData = ReturnType<typeof makePhoneData>

function makeAddressData(element: JapaneseAddressElement) {
  return new ResultData(
    element,
    yup.object({
      zipCode: yup
        .string()
        .matches(/^(\d{3}[-]?\d{4})?$/, {
          message: '正しい郵便番号を入力してください。',
        })
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required()
            .min(1),
        }),

      prefecture: yup
        .string()
        .max(100)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),

      city: yup
        .string()
        .max(100)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),

      area: yup
        .string()
        .max(100)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .string()
            .required(),
        }),

      building: yup
        .string()
        .max(100),
    }),
    (value, data) => {
      data.address = {
        zipCode: value.zipCode || '',
        prefecture: value.prefecture || '',
        city: value.city || '',
        area: value.area || '',
        building: value.building || '',
      }
    },
    {
      zipCode: '100-8111',
      prefecture: '東京都',
      city: '代田区',
      area: '代田１−１',
      building: '',
    }
  )
}

export type AddressData = ReturnType<typeof makeAddressData>

function makeBirthdayData(element: BirthdayElement) {
  return new ResultData(
    element,
    yup.object({
      year: yup.lazy(() => yup
        .number()
        .max(getYear(Date.now()))
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .number()
            .required(),
        })
        .test({
          message: '年・月・日をすべて入力してください。',
          test(year) {
            return !!year || (
              !this.resolve(yup.ref('month')) &&
              !this.resolve(yup.ref('day'))
            )
          },
        })),
      month: yup.lazy(() => yup
        .number()
        .min(1)
        .max(12)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .number()
            .required(),
        })
        .test({
          message: '年・月・日をすべて入力してください。',
          test(month) {
            return !!month || (
              !this.resolve(yup.ref('year')) &&
              !this.resolve(yup.ref('day'))
            )
          },
        })),
      day: yup.lazy(() => yup
        .number()
        .min(1)
        .max(31)
        .when({
          is: () => element.validation.existence.required,
          then: yup
            .number()
            .required(),
        })
        .test({
          message: '年・月・日をすべて入力してください。',
          test(day) {
            return !!day || (
              !this.resolve(yup.ref('year')) &&
              !this.resolve(yup.ref('month'))
            )
          },
        })),
    }),
    (value, data) => {
      const { year, month, day } = value

      if (year && month && day) {
        const birthday = new Date()
        birthday.setFullYear(year, month - 1, day)
        data.birthday = startOfDay(birthday)
      }
    },
    {
      year: 1977,
      month: 1,
      day: 1,
    }
  )
}

export type BirthdayData = ReturnType<typeof makeBirthdayData>

function makeCustomData(element: CustomElement) {
  return new ResultData(
    element,
    yup.object({
      answer: yup.lazy(() =>
        yup
          .string()
          .max(10000)
          .when({
            is: () => element.validation.existence.required,
            then: yup
              .string()
              .required(),
          })
      ),
    }),
    (value, data) => {
      data.custom.push({
        question: element.question,
        answer: value.answer || '',
      })
    },
    {
      answer: '（自由項目内容）',
    }
  )
}

export type CustomData = ReturnType<typeof makeCustomData>

function makeSerialData(element: SerialElement) {
  return new ResultData(
    element,
    yup.object({
      serial: yup
        .string()
        .required()
        .uuid(),
    }),
    (value, data) => {
      data.serial = value.serial
    },
    {
      serial: '00000000-0000-0000-0000-000000000000',
    }
  )
}

export type SerialData = ReturnType<typeof makeSerialData>

export class Result {
  constructor(readonly settings: {
    sample: boolean,
    serial?: string,
  }) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly data: Record<string, ResultData<any, any>> = {}

  readonly privacyPolicy = {
    accepted: false,
    // Submitする時に、acceptedがfalse場合、warningはtrueになります。
    warning: false,
  }

  async validate() {
    if (!this.privacyPolicy.accepted) {
      this.privacyPolicy.warning = true
    }
    return this.privacyPolicy.accepted && _.every(
      await Promise.all(
        Object
          .values(this.data)
          .map(data => data.validate())
      )
    )
  }

  getDataFor(element: Element) {
    if (!(element.id in this.data)) {
      const data = this.makeDataFor(element)
      data.sample = this.settings.sample || false

      // 実装メモ：Vueに通知しておかないと、この変数を使っている表示
      // は更新されない。オブジェクトを設定するのは基本的にリアクティブ
      // ではない。
      Vue.set(this.data, element.id, data)
    }

    return this.data[element.id]
  }

  toEntry() {
    const entry: EntryData = {
      custom: [],
    }

    for (const data of Object.values(this.data)) {
      data.write(entry)
    }

    return entry
  }

  private makeDataFor(element: Element) {
    if (element instanceof TwitterScreenNameElement
      || element instanceof OptionalTwitterScreenNameElement) {
      return makeTwitterScreenNameData(element)

    } else if (element instanceof SerialElement) {
      const data = makeSerialData(element)
      data.value.serial = this.settings.serial || '00000000-0000-0000-0000-000000000000'
      return data

    } else if (element instanceof GenderElement) {
      return makeGenderData(element)

    } else if (element instanceof JapaneseAddressElement) {
      return makeAddressData(element)

    } else if (element instanceof EmailElement) {
      return makeEmailData(element)

    } else if (element instanceof PhoneElement) {
      return makePhoneData(element)

    } else if (element instanceof NameElement) {
      return makeNameData(element)

    } else if (element instanceof KanaNameElement) {
      return makeKanaNameData(element)

    } else if (element instanceof BirthdayElement) {
      return makeBirthdayData(element)

    } else if (element instanceof CustomElement) {
      return makeCustomData(element)

    }

    throw new Error(`未実装な要素：${element.type}`)
  }
}

export const Routes = {
  home: 'home',
  edit: 'edit',
  entry: 'entry',
  confirm: 'confirm',
  finish: 'finish',
  complete: 'complete',
  error: 'error',
  test: 'test',
  duplicate: 'duplicate',
}

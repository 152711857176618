import _ from 'lodash'
import {
  ClassConstructor,
  Exclude,
  Type,
} from 'class-transformer'
import { generate } from '@/utilities/generate'
import {
  Element,
  EmailElement,
  GenderElement,
  JapaneseAddressElement,
  PhoneElement,
  TwitterScreenNameElement,
  OptionalTwitterScreenNameElement,
  CustomElement,
  NameElement,
  KanaNameElement,
  BirthdayElement,
  ElementType,
  SerialElement,
} from '@/models/element'
import {
  Widget,
  DelimiterWidget,
  TextContentWidget,
  PrivacyPolicyWidget,
  FormWidget,
  SplashWidget,
  WidgetType,
  HeaderWidget,
} from '@/models/widget'

const WIDGET_DISCRIMINATOR: {
  name: WidgetType
  value: ClassConstructor<Widget>
}[] = [
  {
    name: 'delimiter',
    value: DelimiterWidget,
  },
  {
    name: 'text',
    value: TextContentWidget,
  },
  {
    name: 'header',
    value: HeaderWidget,
  },
  {
    name: 'privacy-policy',
    value: PrivacyPolicyWidget,
  },
  {
    name: 'splash',
    value: SplashWidget,
  },
  {
    name: 'form',
    value: FormWidget,
  },
]

const ELEMENT_DISCRIMINATOR: {
  name: ElementType
  value: ClassConstructor<Element>
}[] = [
  {
    name: 'twitter-screen-name',
    value: TwitterScreenNameElement,
  },
  {
    name: 'optional-twitter-screen-name',
    value: OptionalTwitterScreenNameElement,
  },
  {
    name: 'serial',
    value: SerialElement,
  },
  {
    name: 'gender',
    value: GenderElement,
  },
  {
    name: 'email',
    value: EmailElement,
  },
  {
    name: 'phone',
    value: PhoneElement,
  },
  {
    name: 'address-japan',
    value: JapaneseAddressElement,
  },
  {
    name: 'birthday',
    value: BirthdayElement,
  },
  {
    name: 'name',
    value: NameElement,
  },
  {
    name: 'name-kana',
    value: KanaNameElement,
  },
  {
    name: 'custom',
    value: CustomElement,
  },
]

export abstract class Layout {

  @Exclude()
  abstract name: string

  @Type(() => Widget, {
    discriminator: {
      property: 'type',
      subTypes: WIDGET_DISCRIMINATOR,
    },
    keepDiscriminatorProperty: true,
  })
  widgets: Widget[] = []

  async validate() {
    return _.every(
      await Promise.all(
        this.widgets.map(
          widget => widget.validate(this.widgets)
        )
      )
    )
  }

  get isValid() {
    return _.every(
      this.widgets.map(
        widget => !widget.error
      )
    )
  }
}

export class EntryLayout extends Layout {

  readonly name = '入力画面'
}

export class ConfirmLayout extends Layout {

  readonly name = '確認画面'
}

export class FinishLayout extends Layout {

  readonly name = '完了画面'
}

export class CompleteLayout extends Layout {

  readonly name = '終了画面'
}

export class Form {

  @Type(() => EntryLayout)
  entry = new EntryLayout()

  @Type(() => ConfirmLayout)
  confirm = new ConfirmLayout()

  @Type(() => FinishLayout)
  finish = new FinishLayout()

  @Type(() => CompleteLayout)
  complete = new CompleteLayout()

  @Type(() => Element, {
    discriminator: {
      property: 'type',
      subTypes: ELEMENT_DISCRIMINATOR,
    },
    keepDiscriminatorProperty: true,
  })
  elements: Element[] = []

  get layouts() {
    return [
      this.entry,
      this.confirm,
      this.finish,
      this.complete,
    ]
  }

  async validate() {
    return _.every(
      await Promise.all([
        ...this.layouts.map(layout => layout.validate()),
        ...this.elements.map(element => element.validate(this.elements)),
      ])
    )
  }

  get areLayoutsValid() {
    return _.every(this.layouts.map(layout => layout.isValid))
  }

  get areElementsValid() {
    return _.every(this.elements.map(element => !element.error))
  }

  get isValid() {
    return this.areLayoutsValid && this.areElementsValid
  }
}

export const FORM_IDS = ['serial', 'twitter-screen-name'] as const

export type FormId = typeof FORM_IDS[number]

// DynamicFormの設定情報
// apps/web/app/javascript/src/member/components/DynamicFormHost.vue にも同じ情報を定義すること
export interface FormSettings {
  id: FormId
}

export function makeDefaultForm(settings: FormSettings | null) {
  const formId = (settings && settings.id) || 'serial'
  return generate(Form, {
    entry: generate(EntryLayout, {
      widgets: [
        generate(HeaderWidget, {
          text: 'キャンペーン当選者フォーム',
        }),
        generate(TextContentWidget),
        generate(FormWidget, {
          fixed: true,
          id: formId,
        }),
        generate(PrivacyPolicyWidget, {
          fixed: true,
          text: 'ご登録いただいた個人情報は本キャンペーンに関するご連絡、賞品等の発送に使用します。<br><br>ご登録いただいた個人情報の取扱いの全部または一部を委託することがあります。<br><br>法律上許されている場合を除き、お客様の個人情報をお客様の同意無しに第三者に開示・提供することはありません。',
        }),
      ],
    }),
    confirm: generate(ConfirmLayout, {
      widgets: [
        generate(HeaderWidget, {
          text: '入力内容確認',
        }),
        generate(TextContentWidget, {
          text: '以下の内容でよろしければ登録ボタンを押してください。',
        }),
        generate(FormWidget, {
          fixed: true,
          preview: true,
          id: formId,
        }),
      ],
    }),
    finish: generate(FinishLayout, {
      widgets: [
        generate(HeaderWidget, {
          text: '登録完了',
        }),
        generate(TextContentWidget, {
          text: '登録ありがとうございました。',
        }),
      ],
    }),
    complete: generate(CompleteLayout, {
      widgets: [
        generate(HeaderWidget, {
          text: '登録期間終了',
        }),
        generate(TextContentWidget, {
          text: '本キャンペーンの入力期間は終了しました。',
        }),
      ],
    }),
    elements: makeDefaultElements(settings),
  })
}

function makeDefaultElements(settings: FormSettings | null) {
  const idElement = settings && settings.id === 'serial'
    ? generate(SerialElement)
    : generate(TwitterScreenNameElement)

  return [
    idElement,
    generate(NameElement),
    generate(KanaNameElement),
    generate(JapaneseAddressElement),
    generate(PhoneElement),
  ]
}

// 空のフォーム．ユーザにフォームのデータを見せたくない場合などに用いる
export function makeEmptyForm() {
  return generate(
    Form,
    {
      entry: generate(EntryLayout, { widgets: [] }),
      confirm: generate(ConfirmLayout, { widgets: [] }),
      finish: generate(FinishLayout, { widgets: [] }),
      complete: generate(CompleteLayout, { widgets: [] }),
      elements: [],
    }
  )
}














import { Component, Prop, Vue } from 'vue-property-decorator'

import { DelimiterWidget } from '@/models/widget'

@Component
export default class DelimiterWidgetView extends Vue {

  @Prop({ required: true })
  widget!: DelimiterWidget
}

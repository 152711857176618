




















import { Component, Prop, Vue } from 'vue-property-decorator'

import ToolbarFrame from '@/components/ToolbarFrame.vue'
import WidgetToolbar from '@/components/WidgetToolbar.vue'
import ElementToolbar from '@/components/ElementToolbar.vue'
import FormWidgetView from '@/components/widgets/FormWidgetView.vue'

import { FormWidget } from '@/models/widget'
import { Form } from '@/models/form'
import { Result } from '@/models/result'

@Component({
  components: {
    ToolbarFrame,
    WidgetToolbar,
    ElementToolbar,
    FormWidgetView,
  },
})
export default class FormWidgetEditor extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  widget!: FormWidget

  @Prop({ required: true })
  result!: Result
}

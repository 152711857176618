









import { Component, Vue } from 'vue-property-decorator'

import { EDITOR_API_BASE_DOMAIN } from '@/utilities/environment'
import EntryApp from '@/apps/EntryApp.vue'
import EditorApp from '@/apps/EditorApp.vue'
import { EditorApi } from './api/editor'
import { EntryApi } from './api/entry'

@Component({
  components: {
    EntryApp,
    EditorApp,
  },
})
export default class App extends Vue {

  readonly edit = this.$route.query.edit === 'true'

  readonly editorApi = new EditorApi(
    handler => window.addEventListener('message', handler),
    message => window.parent.postMessage(message, EDITOR_API_BASE_DOMAIN)
  )

  readonly entryUrl = new URL(window.location.href)
  readonly entryParams = new URLSearchParams(this.entryUrl.search)
  readonly entryQueryPid = this.entryParams.get('pid')
  readonly entryQueryFid = this.entryParams.get('fid')
  readonly entryQuerySid = this.entryParams.get('sid')

  readonly entryApi = new EntryApi(
    this.$route.query.fid as string | null || this.entryQueryFid || '',
    this.$route.query.pid as string | null || this.entryQueryPid || '',
    this.$route.query.sid as string || this.entryQuerySid || ''
  )

  get app() {
    return this.edit ? 'editor' : 'default'
  }

  get api() {
    return this.edit ? this.editorApi : this.entryApi
  }
}

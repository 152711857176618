import _ from 'lodash'

export function reactiveInsert<T>(items: T[], item: T, at: number) {
  if (at >= 0 && at <= items.length) {
    return [
      ...items.slice(0, at),
      item,
      ...items.slice(at),
    ]
  }

  return items
}

export function reactiveShift<T>(items: T[], item: T, offset: number) {
  const from = items.indexOf(item)

  if (from !== -1) {
    const to = from + offset

    if (to >= 0 && to < items.length) {
      const first = Math.min(from, to)
      const second = Math.max(from, to)

      return [
        ...items.slice(0, first),
        items[second],
        ...items.slice(first + 1, second),
        items[first],
        ...items.slice(second + 1),
      ]
    }
  }

  return items
}

export function reactiveRemove<T>(items: T[], item: T) {
  const at = items.indexOf(item)

  if (at !== -1) {
    delete items[at]
    return _.compact(items)
  }

  return items
}

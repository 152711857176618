import { Exclude } from 'class-transformer'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'

import { Alignment } from './alignment'
import { FormId } from './form'

export type WidgetType =
  'delimiter' |
  'text' |
  'header' |
  'privacy-policy' |
  'form' |
  'splash'

export abstract class Widget {

  abstract type: WidgetType

  readonly id = uuidv4()

  fixed = false

  @Exclude()
  schema: yup.AnySchema = yup.object()

  @Exclude()
  error: yup.ValidationError | null = null

  async validate(widgets: Widget[]) {
    try {
      await this.schema.validate(this, {
        abortEarly: false,
        context: {
          widgets,
        },
      })

      this.error = null
      return true

    } catch (error) {
      this.error = error
      return false
    }
  }
}

export abstract class TextWidget extends Widget {

  text = ''

  alignment: Alignment = 'start'

  bold = false

  color = 'black'

  abstract readonly size: number

  @Exclude()
  abstract maxLength: number

  readonly schema = yup.object({
    label: yup
      .lazy(() => yup
        .string()
        .max(this.maxLength)
      ),
    text: yup
      .lazy(() => yup
        .string()
        .max(this.maxLength)
      ),
  })
}

export class DelimiterWidget extends Widget {

  readonly type = 'delimiter'

  width: number = 2
}

export class TextContentWidget extends TextWidget {

  readonly type = 'text'

  readonly size = 16

  readonly maxLength = 10000
}

export class HeaderWidget extends TextWidget {

  readonly type = 'header'

  readonly size = 30

  alignment: Alignment = 'center'

  bold = true

  readonly maxLength = 1000
}

export class PrivacyPolicyWidget extends TextWidget {

  readonly type = 'privacy-policy'

  label = '個人情報の取り扱いについて'

  readonly size = 16

  readonly maxLength = 10000
}

export class FormWidget extends Widget {

  readonly type = 'form'

  preview = false

  id: FormId = 'serial'
}

export class SplashWidget extends Widget {

  readonly type = 'splash'

  url = ''
}



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class RequirementTag extends Vue {

  @Prop({ required: true })
  required!: boolean
}

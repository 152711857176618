


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { isPast, isFuture } from 'date-fns'

import { Form, makeEmptyForm } from '@/models/form'
import { Result } from '@/models/result'

import { EntryApi } from '@/api/entry'

@Component
export default class EntryApp extends Vue {

  @Prop({ required: true })
  api!: EntryApi

  form: Form | null = null

  result: Result | null = null

  error = false

  testCode?: string

  async created() {
    try {
      const response = await this.api.getForm()

      if (response) {
        this.form = response.form
        this.result = response.result

        // フォームの入力開始前かどうか
        // この場合， result.form には null が入っている．
        // result.form が null であることも確認しているのは，フロントの時刻とのズレを懸念しているためだ
        if (isFuture(response.entryStartAt) || response.form === null) {
          // テストモードではフォームを取得できない．代わりに空のフォームを入れておく．
          this.form = makeEmptyForm()

          await this.$router.push({
            name: this.$routes.test,
            // テスト画面から入力画面に戻ってくるときまでクエリを保存する
            // https://gitlab.com/uniquevision/beluga/beluga3/-/issues/8272
            query: this.$route.query,
          })
        }

        // フォームの入力終了後かどうか
        else if (isPast(response.deadlineAt)) {
          await this.$router.push({
            name: this.$routes.complete,
          })
        }

        // シリアルが使用済みかどうか
        else if (response.serialUsedFlag) {
          await this.$router.push({
            name: this.$routes.duplicate,
          })
        }
      }

    } catch (error) {
      await this.$router.push({
        name: this.$routes.error,
      })
    }
  }

  async submit() {
    if (this.result) {
      await this.api.postWinner(this.result, this.testCode)
    }
  }

  async check(testCode: string) {
    if (testCode === '') {
      return false
    }

    const response = await this.api.checkTestCode(testCode)

    if (response && response.validFlag) {
      this.testCode = testCode
      this.form = response.form

      // シリアルが使用済みかどうか
      if (response.serialUsedFlag) {
        await this.$router.push({
          name: this.$routes.duplicate,
        })

      } else {
        await this.$router.push({
          name: this.$routes.entry,
          // テスト画面から入力画面に戻ってくるときまでクエリを保存する
          // https://gitlab.com/uniquevision/beluga/beluga3/-/issues/8272
          query: this.$route.query,
        })
      }

      return true
    }

    return false
  }
}























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { getYear, getDaysInMonth, startOfDay, format } from 'date-fns'

import ElementView from '@/components/ElementView.vue'
import ElementRow from '@/components/ElementRow.vue'
import ElementInput from '@/components/ElementInput.vue'

import { BirthdayElement } from '@/models/element'
import { BirthdayData } from '@/models/result'

@Component({
  components: {
    ElementView,
    ElementRow,
    ElementInput,
  },
})
export default class BirthdayElementView extends Vue {

  @Prop({ required: true })
  element!: BirthdayElement

  @Prop({ required: true })
  data!: BirthdayData

  @Prop({ required: true })
  edit!: boolean

  get value() {
    return this.data.value
  }

  get now() {
    return new Date()
  }

  get years() {
    return _.range(getYear(this.now), 1900, -1)
  }

  get months() {
    return _.range(1, 13)
  }

  get days() {
    const { year, month } = this.data.value

    if (year && month) {
      return _.range(1, (getDaysInMonth(new Date(year, month - 1, 1)) || 31) + 1)

    } else {
      return _.range(1, 31 + 1)

    }
  }

  get birthday() {
    const { year, month, day } = this.value

    if (year && month && day) {
      const birthday = new Date()
      birthday.setFullYear(year, month - 1, day)
      return startOfDay(birthday)
    }

    return undefined
  }

  clear() {
    this.value.year = undefined
    this.value.month = undefined
    this.value.day = undefined
  }

  formatMonth(month: number) {
    return month.toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    })
  }

  formatDay(day: number) {
    return day.toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    })
  }

  formatBirthday(date: Date) {
    return format(date, 'yyyy年MM月dd日')
  }
}













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PaletteItem extends Vue {

  @Prop({ default: false })
  disabled!: boolean
}

import { ENTRY_API_BASE_URL, ENTRY_API_KEY, MAX_TRY_COUNT, BASE_SLEEP_TIME, MIN_SLEEP_TIME } from '@/utilities/environment'
import axios, { AxiosResponse, AxiosError } from 'axios'

export class Client {
  axiosInstance = axios.create({
    baseURL: ENTRY_API_BASE_URL,
    headers: {
      'x-api-key': ENTRY_API_KEY,
    },
  })

  async get<PARAMS, RESPONSE>(path: string, params: PARAMS) {
    const response = await this.executeHttpMethodwithRetry<PARAMS>(
      // eslint-disable-next-line no-return-await
      async (path: string, params) => { return await this.axiosInstance.get(path, params) },
      path,
      params
    )

    return response as AxiosResponse<RESPONSE>
  }

  async post<PARAMS>(path: string, params: PARAMS) {
    const response = await this.executeHttpMethodwithRetry<PARAMS>(
      (path: string, params) => this.axiosInstance.post(path, params),
      path,
      params
    )
    return response as AxiosResponse
  }

  // BASE_SLEEP_TIMEはMAX_TRY_COUNTおよびMIN_SLEEP_TIMEを踏まえて、最大待ち時間の期待値が10s程度となるように算出した
  private exponentialBackoffandJitter(tryCount: number): number {
    const max = Math.max(BASE_SLEEP_TIME ** tryCount, MIN_SLEEP_TIME)
    const min = MIN_SLEEP_TIME
    // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    return Math.random() * (max - min) + min
  }

  private async sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
      return setTimeout(resolve, ms)
    })
  }

  private async executeHttpMethodwithRetry<PARAMS>(method: (path: string, params: PARAMS) => Promise<AxiosResponse>, path: string, params: PARAMS) {
    let tryCount = 0

    while (MAX_TRY_COUNT > tryCount) {
      tryCount += 1
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await method(path, params)

        return response
      } catch (e) {

        const errorStatusCode = (e as AxiosError)?.response?.status

        // 500番台のエラーだった場合はリトライする
        if (MAX_TRY_COUNT >= tryCount && !!errorStatusCode && this.checkErrorStatusCode(errorStatusCode)) {
          // eslint-disable-next-line no-await-in-loop
          await this.sleep(this.exponentialBackoffandJitter(tryCount))
          continue
        }

        throw e
      }
    }
  }

  private checkErrorStatusCode(statusCode: number) {
    return statusCode >= 500 && statusCode < 600
  }
}

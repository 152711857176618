// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T

export function generate<T extends Object>(
  clazz: Constructor<T>,
  options: Partial<T> = {}
): T {
  const instance = new clazz()
  Object.assign(instance, options)
  return instance
}

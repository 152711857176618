























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Dialog extends Vue {

  @Prop({ default: 'default' })
  variant!: 'default' | 'danger'
}

import Vue from 'vue'
import _ from 'lodash'

import { Breakpoint, breakpointController } from '@/utilities/breakpoints'

type When = <T>(value?: T) => T | false

function when<T = true>(breakpoint: Breakpoint, value?: T): T | false {
  if (breakpointController.breakpoint === breakpoint) {
    if (value === undefined) {
      return true as unknown as T

    } else {
      return value
    }
  }

  return false
}

export const whenDesktop = _.curry(when)('desktop') as When
export const whenMobile = _.curry(when)('mobile') as When

Vue.prototype.$desktop = whenDesktop
Vue.prototype.$mobile = whenMobile

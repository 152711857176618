







import { Component, Prop, Vue } from 'vue-property-decorator'
import TextWidgetEditor from '@/components/editors/TextWidgetEditor.vue'
import { HeaderWidget } from '@/models/widget'
import { TextWidgetEditorFeature } from './TextWidgetEditor'

@Component({
  components: {
    TextWidgetEditor,
  },
})
export default class HeaderWidgetEditor extends Vue {

  @Prop({ required: true })
  widget!: HeaderWidget

  get features(): TextWidgetEditorFeature[] {
    return ['color']
  }
}

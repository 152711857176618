












import { Component, Prop, Vue } from 'vue-property-decorator'
import TextWidgetEditor from '@/components/editors/TextWidgetEditor.vue'
import PrivacyPolicyWidgetView from '@/components/widgets/PrivacyPolicyWidgetView.vue'
import TextWidgetContentEditor from '@/components/editors/TextWidgetContentEditor.vue'
import { PrivacyPolicyWidget } from '@/models/widget'
import { Result } from '@/models/result'

@Component({
  components: {
    TextWidgetEditor,
    PrivacyPolicyWidgetView,
    TextWidgetContentEditor,
  },
})
export default class PrivacyPolicyWidgetEditor extends Vue {

  @Prop({ required: true })
  widget!: PrivacyPolicyWidget

  @Prop({ required: true })
  result!: Result
}

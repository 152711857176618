




















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { Layout } from '@/models/form'
import { Widget } from '@/models/widget'
import { reactiveShift, reactiveRemove } from '@/utilities/reactive'

import Toolbar from '@/components/Toolbar.vue'
import ToolbarDelimiter from '@/components/ToolbarDelimiter.vue'
import ShiftTool from '@/components/tools/ShiftTool.vue'
import TrashTool from '@/components/tools/TrashTool.vue'

@Component({
  components: {
    Toolbar,
    ToolbarDelimiter,
    ShiftTool,
    TrashTool,
  },
})
export default class WidgetToolbar extends Vue {

  @Inject()
  layout!: Layout

  @Prop({ required: true })
  widget!: Widget

  shift(offset: number) {
    this.layout.widgets = reactiveShift(this.layout.widgets, this.widget, offset)
  }

  remove() {
    this.layout.widgets = reactiveRemove(this.layout.widgets, this.widget)
  }
}

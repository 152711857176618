



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import { Form } from '@/models/form'

import Dialog from '@/components/Dialog.vue'

@Component({
  components: {
    Dialog,
  },
})
export default class FormValidationErrorDialog extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ default: () => _.noop })
  onAccept!: () => Promise<void>
}































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

import ToolbarFrame from '@/components/ToolbarFrame.vue'
import Tool from '@/components/Tool.vue'
import WidgetToolbar from '@/components/WidgetToolbar.vue'
import SplashWidgetView from '@/components/widgets/SplashWidgetView.vue'

import { SplashWidget } from '@/models/widget'

@Component({
  components: {
    ToolbarFrame,
    Tool,
    WidgetToolbar,
    SplashWidgetView,
  },
})
export default class SplashWidgetEditor extends Vue {

  @Ref('view')
  view!: SplashWidgetView

  @Prop({ required: true })
  widget!: SplashWidget

  clear() {
    this.widget.url = ''
  }

  upload() {
    this.view.triggerSelect()
  }
}









import { Component, Prop, Vue } from 'vue-property-decorator'

import { TextContentWidget } from '@/models/widget'

import TextWidgetRenderer from '@/components/TextWidgetRenderer.vue'

@Component({
  components: {
    TextWidgetRenderer,
  },
})
export default class TextContentWidgetView extends Vue {

  @Prop({ required: true })
  widget!: TextContentWidget
}






































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import Palette from '@/components/Palette.vue'
import WidgetPaletteItem from '@/components/WidgetPaletteItem.vue'

import {
  DelimiterWidget,
  HeaderWidget,
  SplashWidget,
  TextContentWidget,
  Widget,
  WidgetType,
} from '@/models/widget'

@Component({
  components: {
    Palette,
    WidgetPaletteItem,
  },
})
export default class WidgetPalette extends Vue {

  @Prop({ default: () => _.noop })
  onWidget!: (widget: Widget) => Promise<void>

  @Prop({ default: false })
  disabled!: boolean

  async onSelect(type: WidgetType) {
    let widget: Widget | undefined

    switch (type) {
      case 'delimiter':
        widget = new DelimiterWidget()
        break

      case 'text':
        widget = new TextContentWidget()
        break

      case 'header':
        widget = new HeaderWidget()
        break

      case 'splash':
        widget = new SplashWidget()
        break

      default:
        // 自分では作れない。
        break
    }

    if (widget) {
      await this.onWidget(widget)
    }
  }
}
































import { Component, Prop, Vue } from 'vue-property-decorator'

import { Form, Layout } from '@/models/form'
import { Result } from '@/models/result'

import LayoutEditor from '@/components/LayoutEditor.vue'
import LayoutView from '@/components/LayoutView.vue'

@Component({
  components: {
    LayoutEditor,
    LayoutView,
  },
})
export default class LayoutPageTemplate extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  layout!: Layout

  @Prop({ required: true })
  result!: Result

  @Prop({ required: true })
  edit!: boolean
}

import axios from 'axios'

export interface JapaneseAddress {
  prefecture: string
  city: string
  area: string
}

const CLIENT = axios.create({
  baseURL: location.origin,
})

const CACHE: Record<string, Record<string, string[]>> = {}

function sanitizeJapaneseZipCode(zipCode: string | undefined) {
  return (zipCode || '').replace(/\D/g, '')
}

export function isValidJapaneseZipCode(zipCode: string | undefined) {
  return sanitizeJapaneseZipCode(zipCode).length === 7
}

export async function findJapaneseAddressByZipCode(zipCode: string | undefined): Promise<JapaneseAddress | null> {
  zipCode = sanitizeJapaneseZipCode(zipCode)
  const fileKey = zipCode.substring(0, 2)
  const internalKey = zipCode.substring(2)

  if (!(fileKey in CACHE)) {
    try {
      const response = await CLIENT.get(`/zip_codes/${fileKey}.json`)
      CACHE[fileKey] = response.data

    } catch (error) {

      // 2xx系ではなかった。その住所番号は存在しない。
      return null
    }
  }

  const addressData = CACHE[fileKey][internalKey]

  if (!addressData) {
    return null
  }

  return {
    prefecture: addressData[0],
    city: addressData[1],
    area: addressData[2],
  }
}

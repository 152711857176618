



































import { Component, Prop, Provide, Vue } from 'vue-property-decorator'
import { reactiveInsert } from '@/utilities/reactive'

import DelimiterWidgetEditor from '@/components/editors/DelimiterWidgetEditor.vue'
import PrivacyPolicyWidgetEditor from '@/components/editors/PrivacyPolicyWidgetEditor.vue'
import TextWidgetEditor from '@/components/editors/TextWidgetEditor.vue'
import HeaderWidgetEditor from '@/components/editors/HeaderWidgetEditor.vue'
import FormWidgetEditor from '@/components/editors/FormWidgetEditor.vue'
import SplashWidgetEditor from '@/components/editors/SplashWidgetEditor.vue'
import WidgetCreateBar from '@/components/WidgetCreateBar.vue'

import { Form, Layout } from '@/models/form'
import { Widget } from '@/models/widget'
import { Result } from '@/models/result'

@Component({
  components: {
    DelimiterWidgetEditor,
    PrivacyPolicyWidgetEditor,
    TextWidgetEditor,
    HeaderWidgetEditor,
    FormWidgetEditor,
    SplashWidgetEditor,
    WidgetCreateBar,
  },
})
export default class LayoutEditor extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  @Provide()
  layout!: Layout

  @Prop({ required: true })
  result!: Result

  editorFor(widget: Widget) {
    switch (widget.type) {
      case 'delimiter':
        return 'DelimiterWidgetEditor'

      case 'text':
        return 'TextWidgetEditor'

      case 'header':
        return 'HeaderWidgetEditor'

      case 'privacy-policy':
        return 'PrivacyPolicyWidgetEditor'

      case 'form':
        return 'FormWidgetEditor'

      case 'splash':
        return 'SplashWidgetEditor'

      default:
        throw new Error(`未実装なWidget: ${widget.type}`)
    }
  }

  insert(widget: Widget, at: number) {
    this.layout.widgets = reactiveInsert(this.layout.widgets, widget, at)
  }
}

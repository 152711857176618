


























import { Component, Prop, Vue } from 'vue-property-decorator'

import Tool from '@/components/Tool.vue'

@Component({
  components: {
    Tool,
  },
})
export default class RequiredTool extends Vue {

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ required: true })
  value!: boolean
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative"},[_c('div',{staticClass:"tw-absolute tw-w-full tw-bg-green",style:({
      height: '1px',
      top: '12px',
    })}),_c('uv-dialog',{attrs:{"dialog-class":'widget-palette-dialog',"dialog":_vm.dialog,"persistent":false},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',{staticClass:"tw-relative tw-flex tw-items-center tw-justify-center"},[_c('div',_vm._g({staticClass:"tw-mx-auto tw-cursor-pointer tw-bg-green tw-text-white",style:({
            borderRadius: _vm.extended ? '20px' : '50%',
          }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},on),[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-center tw-space-x-1",style:({
              margin: _vm.extended ? '0.25em 0.75em' : '0.25em',
            })},[_c('uv-icon',{staticClass:"tw-text-white",attrs:{"icon":"plus","size":20}}),(_vm.extended)?[_c('div',{staticClass:"tw-text-sm",style:({
                  marginBottom: '1px',
                })},[_vm._v(" ブロックを追加する ")])]:_vm._e()],2)])])]}}])},[_c('WidgetPalette',{attrs:{"on-widget":_vm.doWidget,"disabled":!_vm.canCreateWidget}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
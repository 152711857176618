























































import { Component, Prop, Provide, Vue } from 'vue-property-decorator'

import { FormWidget } from '@/models/widget'
import { Result } from '@/models/result'
import { Element } from '@/models/element'
import { Form } from '@/models/form'

import ElementPalette from '@/components/ElementPalette.vue'
import TwitterScreenNameElementView from '@/components/elements/TwitterScreenNameElementView.vue'
import SerialElementView from '@/components/elements/SerialElementView.vue'
import GenderElementView from '@/components/elements/GenderElementView.vue'
import NameElementView from '@/components/elements/NameElementView.vue'
import KanaNameElementView from '@/components/elements/KanaNameElementView.vue'
import PhoneElementView from '@/components/elements/PhoneElementView.vue'
import EmailElementView from '@/components/elements/EmailElementView.vue'
import BirthdayElementView from '@/components/elements/BirthdayElementView.vue'
import JapaneseAddressElementView from '@/components/elements/JapaneseAddressElementView.vue'
import CustomElementView from '@/components/elements/CustomElementView.vue'
import { reactiveInsert } from '@/utilities/reactive'

@Component({
  components: {
    ElementPalette,
    TwitterScreenNameElementView,
    SerialElementView,
    GenderElementView,
    NameElementView,
    KanaNameElementView,
    PhoneElementView,
    EmailElementView,
    BirthdayElementView,
    JapaneseAddressElementView,
    CustomElementView,
  },
})
export default class FormWidgetView extends Vue {

  @Prop({ required: true })
  @Provide()
  form!: Form

  @Prop({ required: true })
  @Provide()
  widget!: FormWidget

  @Prop({ required: true })
  result!: Result

  @Prop({ default: false })
  edit!: boolean

  dialog = false

  componentFor(element: Element) {
    switch (element.type) {
      case 'twitter-screen-name':
        return 'TwitterScreenNameElementView'

      case 'optional-twitter-screen-name':
        return 'TwitterScreenNameElementView'

      case 'serial':
        return 'SerialElementView'

      case 'gender':
        return 'GenderElementView'

      case 'name':
        return 'NameElementView'

      case 'name-kana':
        return 'KanaNameElementView'

      case 'phone':
        return 'PhoneElementView'

      case 'email':
        return 'EmailElementView'

      case 'birthday':
        return 'BirthdayElementView'

      case 'address-japan':
        return 'JapaneseAddressElementView'

      case 'custom':
        return 'CustomElementView'

      default:
        throw new Error(`未実装な要素：${element.type}`)
    }
  }

  dataFor(element: Element) {
    return this.result.getDataFor(element)
  }

  insert(element: Element) {
    this.dialog = false
    const { elements } = this.form
    this.form.elements = reactiveInsert(elements, element, elements.length)
  }

  get canEditElements() {
    return this.edit
        && !this.widget.preview
  }
}

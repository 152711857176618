




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import { ViewType } from '@/components/TheNavbar'
import { Routes } from '@/router/routes'

@Component
export default class TheNavbar extends Vue {

  @Prop({ required: true })
  view!: ViewType

  @Prop({ required: true })
  edit!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ required: true })
  onSave!: () => Promise<boolean>

  @Prop({ default: () => _.noop() })
  onCancel!: () => Promise<void>

  setRoute(route: keyof (typeof Routes)) {
    this.$router.push({
      name: route,
    })
  }

  setEdit(edit: boolean) {
    this.$emit('update:edit', edit)

    if (edit) {
      this.setView('editor')

    } else {
      this.setView('desktop')
    }
  }

  setView(view: ViewType) {
    this.$emit('update:view', view)
  }

  async save() {
    if (await this.onSave()) {
      this.setEdit(false)
    }
  }

  async cancel() {
    await this.onCancel()
    this.setEdit(false)
  }
}


















import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import Tool from '@/components/Tool.vue'

@Component({
  components: {
    Tool,
  },
})
export default class ShiftTool extends Vue {

  @Prop({ required: true })
  offset!: number

  @Prop({ default: () => _.noop })
  onShift!: (offset: number) => Promise<void>

  async onActivate() {
    await this.onShift(this.offset)
  }
}

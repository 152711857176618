import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { classToPlain, plainToClass } from 'class-transformer'

import { Form, FormSettings } from '@/models/form'
import * as models from './models'

/**
 * Belugaから動的フォームを編集するAPI。
 */
export class EditorApi {
  onData: (form: Form | null, settings: FormSettings | null) => void = _.noop
  onDisabled: (disabled: boolean) => void = _.noop

  private readonly pendingUploads: Record<string, (url: string) => void> = {}

  constructor(
    listen: (handler: (message: MessageEvent) => void) => void,
    private readonly post: <M>(message: M) => void
  ) {
    listen(_.bind(this.handle, this))
  }

  sendReady() {
    const message: models.DynamicFormReadyRequest = {
      type: 'dynaform/request/ready',
    }

    this.post(message)
  }

  sendUpdate(form: Form) {
    const message: models.DynamicFormUpdateRequest = {
      type: 'dynaform/request/update',
      form: classToPlain(form),
    }

    this.post(message)
  }

  sendStatus(status: {
    edit: boolean
  }) {
    const message: models.DynamicFormStatusRequest = {
      type: 'dynaform/request/status',
      ...status,
    }

    this.post(message)
  }

  async sendUpload(file: File): Promise<string> {
    const message: models.DynamicFormUploadRequest = {
      type: 'dynaform/request/upload',
      id: uuidv4(),
      file,
    }

    let resolver: (url: string) => void

    const promise = new Promise<string>(resolve => {
      resolver = url => resolve(url)
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.pendingUploads[message.id] = resolver!
    this.post(message)
    return promise
  }

  private handle(event: MessageEvent) {
    const { data } = event

    if (!data) {
      return
    }

    if (typeof data !== 'object') {
      return
    }

    if (!data.type) {
      return
    }

    switch (data.type) {
      case 'dynaform/response/data':
        this.handleData(data as models.DynamicFormDataResponse)
        break

      case 'dynaform/response/upload':
        this.handleUpload(data as models.DynamicFormUploadResponse)
        break

      case 'dynaform/response/disabled':
        this.handleDisabled(data as models.DynamicFormDisabledResponse)
        break

      default:
        // 未実装、あるいはこのバージョンにはないイベント。
    }
  }

  private handleData(message: models.DynamicFormDataResponse) {
    const { form, settings } = message

    if (
      form &&
      typeof form === 'object' &&
      Object.keys(form).length > 0
    ) {
      this.onData(plainToClass(Form, form), settings || null)

    } else {
      this.onData(null, settings || null)
    }
  }

  private handleUpload(message: models.DynamicFormUploadResponse) {
    const { id, url } = message
    const resolver = this.pendingUploads[id]

    if (resolver) {
      delete this.pendingUploads[id]
      resolver(url || '')
    }
  }

  private handleDisabled(message: models.DynamicFormDisabledResponse) {
    this.onDisabled(message.disabled || false)
  }
}

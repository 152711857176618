import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'

import { GTM_ID } from '@/utilities/environment'
import { makeRouter } from '@/router'

Vue.use(VueGtm, {
  id: GTM_ID,
  vueRouter: makeRouter(),
})

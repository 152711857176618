










































































import { Component, Prop, Provide, Vue } from 'vue-property-decorator'

import WidgetToolbar from '@/components/WidgetToolbar.vue'
import TextWidgetRenderer from '@/components/TextWidgetRenderer.vue'
import TextWidgetContentEditor from '@/components/editors/TextWidgetContentEditor.vue'
import ToolbarFrame from '@/components/ToolbarFrame.vue'
import Tool from '@/components/Tool.vue'
import { TextWidget } from '@/models/widget'
import AlignTool from '@/components/tools/AlignTool.vue'
import ColorTool from '@/components/tools/ColorTool.vue'
import { TEXT_WIDGET_EDITOR_FEATURES, TextWidgetEditorFeature } from '@/components/editors/TextWidgetEditor'

interface ContentEditor {

  // ボルドモードが効いているかどうか。
  readonly bold: boolean

  // 次のコマンドがどのテキストに当てはまるか。
  readonly range: Range | null

  // ボルドモードを実行する時。
  onBold(): void

  // リンクを作る時。
  onLink(): void
}

@Component({
  components: {
    ToolbarFrame,
    WidgetToolbar,
    Tool,
    TextWidgetContentEditor,
    TextWidgetRenderer,
    AlignTool,
    ColorTool,
  },
})
export default class TextWidgetEditor extends Vue {

  @Prop({ default: () => TEXT_WIDGET_EDITOR_FEATURES })
  features!: TextWidgetEditorFeature[]

  @Prop({ required: true })
  widget!: TextWidget

  private contentEditor: ContentEditor | null = null

  @Provide()
  get editor() {
    return this
  }

  listen(editor: ContentEditor | null) {
    this.contentEditor = editor
  }

  get isBold() {
    return this.contentEditor
        && this.contentEditor.bold
  }

  triggerBold() {
    if (this.contentEditor) {
      this.contentEditor.onBold()
    }
  }

  get canLink() {
    return this.contentEditor
        && this.contentEditor.range
  }

  triggerLink() {
    if (this.contentEditor) {
      this.contentEditor.onLink()
    }
  }
}

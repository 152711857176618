/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: '必須項目です。',
  },
  string: {
    max: '${max}文字以内で入力してください。',
    min: '${min}文字以上を入力してください。',
  },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex",class:[
    _vm.$mobile('tw-flex-col'),
    _vm.$desktop('tw-flex-row tw-items-start') ]},[_c('div',{staticClass:"tw-flex tw-space-x-2 tw-mb-1 tw-justify-between",class:[
      _vm.$mobile('tw-flex-row tw-justify-end tw-space-x-reverse tw-py-1 tw-px-2 tw-mt-1 tw-items-start'),
      !_vm.compact && _vm.$mobile('tw-mb-2'),
      _vm.$desktop('tw-flex-row tw-justify-start tw-w-1/3 tw-pr-2 tw-items-center') ],style:(_vm.highlight?_vm.$mobile({
      background: '#F6F6F6'
    }):'')},[_c('div',{staticClass:"tw-font-bold tw-break-all"},[_vm._t("label")],2),_c('RequirementTag',{staticClass:"tw-flex-shrink-0",class:_vm.required === undefined && 'tw-invisible',attrs:{"required":_vm.required}})],1),_c('div',{staticClass:"tw-flex tw-break-all",class:[
      _vm.$mobile('tw-flex-col tw-space-y-2'),
      _vm.$desktop('tw-flex-row tw-space-x-8 tw-w-2/3 tw-pl-2') ]},[_vm._t("inputs")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }


















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { FormWidget } from '@/models/widget'

@Component
export default class ElementView extends Vue {

  @Inject()
  widget!: FormWidget

  @Prop({ default: false })
  preview!: boolean

  @Prop({ default: false, type: Boolean })
  compact!: boolean

  @Prop({ default:  true })
  withBorderBottom!: boolean

}

import Vue from 'vue'
import _ from 'lodash'

export const BREAKPOINTS = ['desktop', 'mobile'] as const
export type Breakpoint = typeof BREAKPOINTS[number]

interface BreakpointState {
  current: Breakpoint
  forced: Breakpoint | false
}

class BreakpointController {
  constructor() {
    window.addEventListener('resize', _.bind(this.onResize, this))
    this.onResize()
  }

  private state: BreakpointState = Vue.observable({
    current: 'mobile',
    forced: false,
  })

  get breakpoint() {
    return this.state.forced || this.state.current
  }

  force(breakpoint: Breakpoint) {
    this.state.forced = breakpoint
  }

  private onResize() {
    const width = window.innerWidth

    if (width <= 812) {
      this.state.current = 'mobile'

    } else {
      this.state.current = 'desktop'
    }
  }
}

export const breakpointController = new BreakpointController()

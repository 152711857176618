





































import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { classToPlain, plainToClass } from 'class-transformer'

import { EditorApi } from '@/api/editor'
import { Breakpoint, breakpointController } from '@/utilities/breakpoints'

import { Form, makeDefaultForm } from '@/models/form'
import { Result } from '@/models/result'

import TheNavbar from '@/components/TheNavbar.vue'
import { ViewType } from '@/components/TheNavbar'
import FormValidationErrorDialog from '@/components/FormValidationErrorDialog.vue'

@Component({
  components: {
    TheNavbar,
    FormValidationErrorDialog,
  },
})
export default class EditorApp extends Vue {

  @Prop({ required: true })
  @Provide()
  api!: EditorApi

  form: Form | null = null

  cleanForm: Form | null = null

  result: Result | null = null

  edit = false

  disabled = false

  view: ViewType = 'desktop'

  errorDialog = false

  async created() {
    this.api.onData = (form, settings) => {
      this.result = new Result({
        sample: false,
      })

      this.reset(form || makeDefaultForm(settings))
      if (!form) {
        this.save()
      }
    }

    this.api.onDisabled = disabled => {
      this.reset(this.cleanForm)
      this.disabled = disabled
    }

    this.api.sendReady()
  }

  async save() {
    if (!this.form) {
      return false

    }

    await this.form.validate()

    if (!this.form.isValid) {
      this.errorDialog = true
      return false
    }

    await this.api.sendUpdate(this.form)
    await this.reset(this.form)
    return true
  }

  async cancel() {
    await this.reset(this.cleanForm)
  }

  async reset(form: Form | null) {
    this.form = form
    this.cleanForm = plainToClass(Form, classToPlain(this.form))

    // デフォルトを設定した場合、親アプリが知るわけがない！
    if (!form) {
      await this.save()
    }
  }

  @Watch('view')
  @Watch('$route')
  cleanResultOnSomethingChanged() {
    let sample = false

    // 確認画面以降は、模擬データを表示する。
    if (this.$route.name !== this.$routes.entry) {
      sample = true
    }

    this.result = new Result({
      sample,
    })
  }

  @Watch('view', { immediate: true })
  forceBreakpointOnViewChanged() {
    let breakpoint: Breakpoint

    if (this.view === 'mobile') {
      breakpoint = 'mobile'

    } else {
      breakpoint = 'desktop'
    }

    breakpointController.force(breakpoint)
  }

  @Watch('edit')
  notifyApiOnEditChanged() {
    this.api.sendStatus({
      edit: this.edit,
    })
  }
}
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Form, Layout } from '@/models/form'
import { Result } from '@/models/result'
import { Widget } from '@/models/widget'

import DelimiterWidgetView from '@/components/widgets/DelimiterWidgetView.vue'
import TextContentWidgetView from '@/components/widgets/TextContentWidgetView.vue'
import HeaderWidgetView from '@/components/widgets/HeaderWidgetView.vue'
import PrivacyPolicyWidgetView from '@/components/widgets/PrivacyPolicyWidgetView.vue'
import FormWidgetView from '@/components/widgets/FormWidgetView.vue'
import SplashWidgetView from '@/components/widgets/SplashWidgetView.vue'

@Component({
  components: {
    DelimiterWidgetView,
    TextContentWidgetView,
    HeaderWidgetView,
    PrivacyPolicyWidgetView,
    FormWidgetView,
    SplashWidgetView,
  },
})
export default class LayoutView extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  layout!: Layout

  @Prop({ required: true })
  result!: Result

  componentFor(widget: Widget) {
    switch (widget.type) {
      case 'delimiter':
        return 'DelimiterWidgetView'

      case 'text':
        return 'TextContentWidgetView'

      case 'header':
        return 'HeaderWidgetView'

      case 'privacy-policy':
        return 'PrivacyPolicyWidgetView'

      case 'form':
        return 'FormWidgetView'

      case 'splash':
        return 'SplashWidgetView'

      default:
        throw new Error(`未実装なwidget: ${widget.type}`)
    }
  }
}

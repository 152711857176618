










import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import {
  JapaneseAddress,
  isValidJapaneseZipCode,
  findJapaneseAddressByZipCode,
} from '@/utilities/japanese_address'

@Component
export default class JapaneseAddressSearchButton extends Vue {

  @Prop({ required: true })
  zipCode?: string

  @Prop({ default: () => _.noop })
  onAddress!: (address: JapaneseAddress) => Promise<void>

  get canSearch() {
    return isValidJapaneseZipCode(this.zipCode)
  }

  async search() {
    const address = await findJapaneseAddressByZipCode(this.zipCode)

    if (address) {
      await this.onAddress(address)
    }
  }
}




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import { Widget } from '@/models/widget'
import WidgetPalette from '@/components/WidgetPalette.vue'

@Component({
  components: {
    WidgetPalette,
  },
})
export default class WidgetCreateBar extends Vue {

  @Prop({ required: true })
  widgets!: Widget[]

  @Prop({ default: () => _.noop })
  onWidget!: (widget: Widget) => Promise<void>

  dialog = false

  hover = false

  get extended() {
    return this.dialog || this.hover
  }

  async doWidget(widget: Widget) {
    await this.onWidget(widget)
    this.dialog = false
  }

  get canCreateWidget() {
    return this.widgets.length < 30
  }
}

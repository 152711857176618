











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import TextWidgetRenderer from '@/components/TextWidgetRenderer.vue'
import { PrivacyPolicyWidget } from '@/models/widget'
import { Result } from '@/models/result'

@Component({
  components: {
    TextWidgetRenderer,
  },
})
export default class PrivacyPolicyWidgetView extends Vue {

  @Prop({ required: true })
  widget!: PrivacyPolicyWidget

  @Prop({ required: true })
  result!: Result

  @Prop({ default: false })
  edit!: boolean

  // warningが出た状態でチェックボックスが入れられた場合はwarningを解除する
  @Watch('result.privacyPolicy.accepted')
  watchCheckbok() {
    if (this.result.privacyPolicy.warning && this.result.privacyPolicy.accepted) {
      this.result.privacyPolicy.warning = false
    }
  }
}

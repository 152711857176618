










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DynamicFormButton extends Vue {
  @Prop({ required: true })
  onClick!: () => void

  @Prop({ required: true })
  title!: string

  @Prop({ required: true })
  disabled!: boolean
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-flex tw-flex-col tw-justify-center",style:({
    minHeight: '20px',
  })},[_c('div',{class:[
      _vm.position === 'top-right' && 'tw-border tw-border-solid tw-rounded tw-px-2 tw-py-5',
      _vm.focus ? 'tw-border-green' : 'tw-border-gray-light'
    ],on:{"focusin":function($event){_vm.focus = true},"focusout":function($event){_vm.focus = false}}},[_vm._t("target")],2),_c('div',{staticClass:"tw-absolute tw-bg-white",class:[
      _vm.position === 'top-right' && 'tw-right-0 tw-top-0 tw-mr-2 tw--mt-4',
      _vm.position === 'center-right' && 'tw-right-0 tw-mr-2'
    ],style:({ zIndex: 1 })},[_vm._t("toolbar")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }


























import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

import { Form } from '@/models/form'

import LayoutPageTemplate from '@/components/LayoutPageTemplate.vue'
import { Result } from '@/models/result'

@Component({
  components: {
    LayoutPageTemplate,
  },
})
export default class ConfirmPage extends Vue {

  @Prop({ required: true })
  form!: Form

  @Prop({ required: true })
  result!: Result

  @Prop({ default: false })
  edit!: boolean

  @Prop({ default: () => _.noop })
  onSubmit!: () => Promise<void>

  active = false

  async back() {
    await this.$router.push({
      name: this.$routes.entry,
    })
  }

  async submit() {
    this.active = true

    /**
     * ブラウザバックでページ遷移すると、入力内容を空にした状態でConfirmPageに入れてしまう。
     * そのため、情報登録時にもバリデーションをかける
     */
    if (!await this.result.validate()) {
      await this.$router.push({
        name: this.$routes.entry,
      })
      // ここで明示的にreturnしておかないと、エラーが解消した瞬間にonSubmitされてしまう
      return
    }

    try {
      await this.onSubmit()
      await this.$router.push({
        name: this.$routes.finish,
      })

    } catch (error) {
      await this.$router.push({
        name: this.$routes.error,
      })

    } finally {
      this.active = false
    }
  }
}























import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import { Form } from '@/models/form'
import { Element } from '@/models/element'
import { reactiveShift, reactiveRemove } from '@/utilities/reactive'

import Toolbar from '@/components/Toolbar.vue'
import ShiftTool from '@/components/tools/ShiftTool.vue'
import TrashTool from '@/components/tools/TrashTool.vue'
import RequiredTool from '@/components/tools/RequiredTool.vue'

@Component({
  components: {
    Toolbar,
    ShiftTool,
    TrashTool,
    RequiredTool,
  },
})
export default class ElementToolbar extends Vue {

  @Inject()
  form!: Form

  @Prop({ required: true })
  element!: Element

  shift(offset: number) {
    this.form.elements = reactiveShift(this.form.elements, this.element, offset)
  }

  remove() {
    this.form.elements = reactiveRemove(this.form.elements, this.element)
  }
}

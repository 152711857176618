














import { Component, Prop, Vue } from 'vue-property-decorator'

import { TextWidget } from '@/models/widget'

@Component
export default class TextWidgetRenderer extends Vue {

  @Prop({ required: true })
  widget!: TextWidget

  get style(): Partial<CSSStyleDeclaration> {
    const {
      alignment,
      bold,
      size,
      color,
    } = this.widget

    const style: Partial<CSSStyleDeclaration> = {}

    if (size) {
      style.fontSize = `${size}px`

    } else {
      style.fontSize = '16px'
    }

    if (bold) {
      style.fontWeight = 'bold'
    }

    if (color) {
      style.color = color
    }

    switch (alignment) {
      case 'start':
        style.textAlign = 'left'
        break

      case 'center':
        style.textAlign = 'center'
        break

      case 'end':
        style.textAlign = 'right'
        break

      default:
    }

    return style
  }
}
